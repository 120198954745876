@import '../../Utilities';

footer {
  position: relative;
  bottom: 0;
  width: 100%;
  margin: auto;
  z-index: 100;
  font-size: 1.5rem;
  background-color: $bg-color-dark-grey;

  .footer-content {
    width: 90%;
    max-width: 125rem;
    height: 10rem;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      width: 33%;
    }

    .scroll-to-top {
      width: 33%;

      .arrow-up {
        text-transform: uppercase;
        font-size: 4rem;
        padding: .5rem;
        border-radius: 50%;
        background-color: #eee;
        color: $text-color-dark;
        cursor: pointer;
        transition: all .1s;

        &:hover {
          transform: scale(1.1);
          color: $btn-color-primary;
        }
      }
    }

    div:last-child {
      text-align: right;
    }

    .footer-icons {
      font-size: 2rem;
      display: flex;
      justify-content: space-around;
      align-items: center;

      & *:hover {
        cursor: pointer;
        color: $btn-color-primary;
      }
    }

  }
}