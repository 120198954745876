@import './Utilities';

.wrapper {
  background-color: $bg-color-black;
  color: $text-color-light;
  overflow: hidden;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    position: fixed;
    background: url(./img/bg-dark-edit-02.jpg) no-repeat center center/cover;
    // filter: invert(1);
    opacity: .4;
  }
}