@import '../../Utilities';

.contact-container {
  height: 100%;
  margin: auto;
  position: relative;
  filter: $drop-shadow-white-20-invert;

  @include respond(desktop-lg) {
    filter: $drop-shadow-white-40-invert;
  }

  .content-container {
    background-color: $bg-color-dark-grey;
    clip-path: polygon(0 7%, 100% 0, 100% 100%, 0 100%);
    padding: 5rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include respond(desktop-lg) {
      clip-path: polygon(0 20%, 100% 0, 100% 100%, 0 100%);
      padding: 15rem 0 5rem 0;
    }
  }


  .contact-heading {
    text-align: center;

    @keyframes bellRinging {
      from {
        transform: rotate(0deg);
      }
      25% {
        transform: rotate(-15deg);
      }
      75%{
        transform: rotate(15deg);
      }
      to {
        transform: rotate(0deg);
      }
    }

    img {
      position: relative;
      animation-duration: .3s;
      animation-delay: .3s;
      animation-iteration-count: 4;
      animation-timing-function: ease-in-out;
    }

    h2 {
      font-family: $font-heading;
      text-transform: uppercase;
      font-weight: 500;
      font-size: $font-size-section-heading;
    }
  }

  form {
    position: relative;
    width: 90%;
    max-width: 50rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .input-group {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 1rem;

      label {
        padding-bottom: .5rem;
      }

      input,
      textarea {
        padding: 1rem;
        border: none;
        border-radius: 4px;
        font-size: 1.6rem;
        font-family: $font-main;

        &:focus {
          outline: 2px solid $btn-color-secondary;
          outline-offset: 2px;
        }
      }

      textarea {
        min-height: 20rem;
        min-width: 20rem;
        max-width: 100rem;
      }
    }
  }

  .btn-contact {
    margin: 3rem 0 0 0;
    font-size: 2rem;
  }
}