@import '../../Utilities';

.navbar {
  width: 90%;
  margin: auto;
  min-height: 7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 100;

  .nav-logo-container {
    width: 0;

    .nav-logo-name {
      max-width: 15rem;
    }
  }

  .flex-navigation {
    width: 50%;
    display: flex;
    justify-content: space-between;
    display: none;
    list-style-type: none;
    font-size: 2rem;

    @include respond(desktop-lg) {
      display: flex;
    }

    .nav-dark-light-toggle {
      width: 20px;
      height: 20px;
      cursor: pointer;

      &:hover {
        color: $btn-color-primary-blue;
      }
    }
  }

  .hamburger {
    width: 1000%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    @include respond(desktop-lg) {
      display: none;
    }

    .hamburger-content {
      height: 100%;
      position: relative;

      div {
        background-color: #fff;
        position: relative;
        border-radius: 5px;
        width: 4rem;
        height: 4px;
        transform-origin: center;
        z-index: 100;
        transition: all .4s ease-in-out;

        &:not(:last-child) {
          margin-bottom: 1rem;
        }

        &:first-child {
          top: 0;
        }
      }

      #hamburgerToggle {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(4);
        opacity: 0;
        cursor: pointer;
        z-index: 10000;

        @include respond(desktop-lg) {
          display: none;
        }
      }

      #hamburgerToggle:checked~div {
        transform: rotate(45deg) translate(1rem, 1rem);
      }

      #hamburgerToggle:checked~div:nth-child(3) {
        opacity: 0;
        transform: rotate(0) scale(.2);
      }

      #hamburgerToggle:checked~div:last-child {
        transform: rotate(-45deg) translate(1rem, -1rem);
      }

      #hamburgerToggle:checked .toggle-menu {
        transform: translate(0);
      }
    }

    .toggle-menu {
      position: absolute;
      left: -5%;
      top: 0;
      width: 100vw;
      height: 100vh;
      display: flex;
      align-items: center;
      transform: translateY(-100%);
      visibility: hidden;
      transition: all 0.3s ease-in-out;
      z-index: 50;
      opacity: 0;

      @include respond(desktop-lg) {
        display: none;
      }

      &:before {
        position: absolute;
        content: '';
        width: 100vw;
        height: 100%;
        background-color: $bg-color-black;
        opacity: 0.95;
      }

      .toggle-menu-content {
        list-style-type: none;
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        padding: 2rem;
        left: 50%;
        height: 80vh;
        transform: translateX(-50%);
        font-size: 4rem;

        a {
          text-align: left;
        }
      }
    }

    .show-toggle-menu {
      transform: translateY(0);
      visibility: visible;
      opacity: 1;
    }
  }
}

li {
  font-family: $font-heading;
  text-transform: uppercase;
  color: inherit;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: $btn-color-primary;
  }
}


.nav-dark-light-toggle {
  cursor: pointer;

  &:hover {
    color: $btn-color-primary-blue;
  }

  &:active {
    color: darken($btn-color-primary, $amount: 20);
  }
}