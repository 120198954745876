@import '../../Utilities.scss';

.work-container {
  // border: 1px solid #008000;
  position: relative;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  filter: $drop-shadow-white-20 $drop-shadow-white-20-invert;

  @include respond(desktop-lg) {
    filter: $drop-shadow-white-40 $drop-shadow-white-40-invert;

  }

  .content-container {
    background-color: $bg-color-dark-grey;
    clip-path: polygon(0 $polygon-small-low, 100% 0, 100% 100%, 0 $polygon-small-high);
    padding: 15rem 0 20rem 0;
    width: 100%;

    @include respond(desktop-lg) {
      clip-path: polygon(0 $polygon-big-low, 100% 0, 100% 100%, 0 $polygon-big-high);
      padding: 30rem 0;
      transform: translateY(-3rem);
    }
  }

  .work-heading-container {
    max-width: 110rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: auto;
    margin-bottom: 2rem;
    position: relative;
    z-index: 2;

    .work-icon {
      font-size: clamp(2.5rem, 5vw, 3.5rem);
      cursor: pointer;
      transition: all .2s;
      border-radius: 50%;
    }

    .work-icon-active {
      outline: 1px dashed #fff;
      outline-offset: 1rem;
      border-radius: 50%;
      color: $btn-color-primary;
    }

    h2 {
      font-family: $font-heading;
      font-size: $font-size-section-heading;
      text-transform: uppercase;
      padding: 2rem;
      font-weight: 500;
    }
  }

  .github-container {
    // border: 1px solid red;
    width: 90%;
    max-width: 100rem;
    margin: auto;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 6rem;
    padding-bottom: 4rem;

  }

  .arrow-container {
    width: 95%;
    max-width: 125rem;
    font-size: 3rem;
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;

    .arrow {
      cursor: pointer;
    }
  }
}