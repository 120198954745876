@import "../../Utilities.scss";

.card-container {
  position: relative;
  z-index: 1;
  width: 40rem;
  min-height: 40rem;
  min-width: 25rem;
  margin: auto;
  // margin-bottom: 5rem;
  box-shadow: 0 0 0 1rem #1f1f1f52, 0 0 0 2rem #6262620b;

  background-color: #111;
  border-radius: 15px;

  cursor: pointer;
  // background-size: cover !important;
  transition: all 0.4s ease-in-out;
  overflow: hidden;

  .img-wrapper {
    background-color: #000;

    img {
      width: 100%;
      object-fit: contain;
      transition: all 0.3s;
      box-shadow: 0 0 1px transparent;
      opacity: 0.8;

      &:hover {
        transform: scale(1.1);
        opacity: 1;
      }
    }
  }

  .card-description {
    position: absolute;
    width: 100%;
    min-height: 20rem;
    padding: 1.5rem;
    line-height: 1.8;
    bottom: 0;
    left: 0;
    background-color: #1f1f1f;
    cursor: default;

    h3 {
      text-align: center;
    }

    a {
      text-decoration: none;
      font-weight: 700;
      color: $btn-color-secondary;
      text-align: right;
      // text-transform: uppercase;
      // color: $btn-color-secondary;
    }
  }
}
